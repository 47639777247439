import Typography from "typography"
import kirkhamTheme from "typography-theme-kirkham"

kirkhamTheme.headerFontFamily = ["Karla", "helvetica neue", "helvetica", "arial"]
kirkhamTheme.googleFonts = kirkhamTheme.googleFonts.push({
    name: "Karla",
    styles: ["400", "400i", "700", "700i"]
})

kirkhamTheme.headerFontFamily = ["Karla", "helvetica neue", "helvetica", "arial"]
kirkhamTheme.bodyFontFamily = ["Karla", "helvetica neue", "helvetica", "arial"]

const typography = new Typography(kirkhamTheme)

export default typography


export const rhythm = typography.rhythm